<template>
    <v-alert
      prominent
      type="error"
      id="closed"
      v-if="visible"
    >
      <v-row align="center">
        <v-col class="grow">
          Vabandame, Rohuaia Kohviku tellimissüsteem on suletud 29.03 - 30.03. Tellimiseks kasutage Wolt'i või Bolt'i.
        </v-col>
        <v-col class="shrink">
          <v-btn icon @click="visible = false">
            <v-icon id="close">
                mdi-close
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
</template>
<script>
export default {
    name: 'ClosedAlert',
    data() {
        return {
            visible: true,
        }
    }
}
</script>
<style #scoped> 
#closed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}
#close {
    font-size: 30px;
}
</style>